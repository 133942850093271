<template>
  <div
    id="brand-uph-edit-modal"
    ref="brandUphEditModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit uph settings
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="uphItem.incentivePack"
              :errors="v$.uphItem.incentivePack.$errors"
              description="Pack Employee Incentive UPH Threshold"
              label="Pack Employee Incentive UPH Threshold"
              @blur="v$.uphItem.incentivePack.$touch"
            />
            <TextInput
              v-model="uphItem.incentivePick"
              :errors="v$.uphItem.incentivePick.$errors"
              description="Pick Employee Incentive UPH Threshold"
              label="Pick Employee Incentive UPH Threshold"
              @blur="v$.uphItem.incentivePick.$touch"
            />
            <TextInput
              v-model="uphItem.expectedPack"
              :errors="v$.uphItem.expectedPack.$errors"
              description="Pack KPI UPH Threshold"
              label="Pack KPI UPH Threshold"
              @blur="v$.uphItem.expectedPack.$touch"
            />
            <TextInput
              v-model="uphItem.expectedPick"
              :errors="v$.uphItem.expectedPick.$errors"
              description="Pick KPI UPH Threshold"
              label="Pick KPI UPH Threshold"
              @blur="v$.uphItem.expectedPick.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="brandUphEditConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The uph for brand will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>

<script>

import { mapActions } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import { integer, minValue, required } from '@vuelidate/validators';

const defaultItem = {
  id: null,
  brand: null,
  brandCode: null,
  brandId: null,
  expectedPick: null,
  expectedPack: null,
  incentivePick: null,
  incentivePack: null
};
export default {
  name: 'BrandUphEditModal',

  components: {
    ConfirmationModal,
    TextInput,
    Spinner
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modalViewType: null,
      confirmationModalSave: null,
      brandUphEditModal: null,
      initialized: false,
      uphItem: {
        id: null,
        brand: null,
        brandCode: null,
        brandId: null,
        expectedPick: null,
        expectedPack: null,
        incentivePick: null,
        incentivePack: null
      }
    };
  },
  validations() {
    return {
      uphItem: {
        expectedPick: {
          required,
          integer,
          minValue: minValue(0),
        },
        expectedPack: {
          required,
          integer,
          minValue: minValue(0),
        },
        incentivePick: {
          required,
          integer,
          minValue: minValue(0),
        },
        incentivePack: {
          required,
          integer,
          minValue: minValue(0),
        }
      }
    };
  },
  computed: {
    isSaveButtonDisabled() {
      return !this.initialized;
    },
  },
  async mounted() {
    this.brandUphEditModal = new Modal(this.$refs['brandUphEditModal']);
    this.confirmationModalSave = this.$refs['brandUphEditConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchBrandUphById: 'brandManagement/fetchBrandUphById',
      updateBrandUph: 'brandManagement/updateBrandUph',
      fetchBrandUph: 'brandManagement/fetchBrandUph',
      addBrandUph: 'brandManagement/addBrandUph'
    }),
    async initModal(uphId) {
      this.initialized = false;
      this.brandUphEditModal.show();
      const brandUph = uphId ? await this.fetchBrandUphById(uphId) : { ...defaultItem };
      this.updateItemData(brandUph);
      this.initialized = true;
    },
    async initAddModal(brand, brandCode, brandId) {
      this.initialized = false;
      this.brandUphEditModal.show();
      const brandUph = { ...defaultItem };
      brandUph.brand = brand;
      brandUph.brandCode = brandCode;
      brandUph.brandId = brandId;
      this.updateItemData(brandUph);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.uphItem.id = newValue.id;
      this.uphItem.brand = newValue.brand;
      this.uphItem.brandCode = newValue.brandCode;
      this.uphItem.brandId = newValue.brandId;
      this.uphItem.expectedPack = newValue.expectedPack;
      this.uphItem.expectedPick = newValue.expectedPick;
      this.uphItem.incentivePack = newValue.incentivePack;
      this.uphItem.incentivePick = newValue.incentivePick;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.brandUphEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.uphItem
      };
      try {
        if (this.uphItem.id) {
          await this.updateBrandUph({ uphId: this.uphItem.id, dataToSave });
        } else {
          await this.addBrandUph(dataToSave);
        }
        this.$toast.success('Uph successfully updated');
        await this.fetchBrandUph(this.uphItem.brandId);
      } catch (e) {
        this.$toast.error('Failed to save changes.');
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>

<style scoped>

</style>
